<template>
  <div class="report-creation-wrapper">

    <SelectReportType
    :report="report"
    v-if="report.type === undefined && currentStep === 'SELECT_TYPE'"
    class="mb-3"
    @updateReportType="updateReportType" />

    <WidgetReportKindSelector
    class="mb-2"
    :report="report"
    v-if="report.type === 'widget' && currentStep === 'SELECT_WIDGET_REPORT_KIND'"
    @nextStep="nextStep"
    @previousStep="previousStep"
    @updateWidgetReportKind="updateWidgetReportKind" />

    <CreateWidgetReport
    :report="report"
    @nextStep="nextStep"
    @previousStep="previousStep"
    @updateCreationFields="updateCreationFields"
    v-if="report.type === 'widget' && currentStep === 'BUILD_WIDGET_REPORT'"/>

    <ReviewReportCreation
    @nextStep="nextStep"
    @buildReport="buildReport"
    @previousStep="previousStep"
    :settings="settings"
    v-if="currentStep === 'REVIEW_REPORT'"/>

    <BuildingReport 
    v-if="currentStep === 'INITIATE_BUILD_REPORT'"/>

  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import REPORT from '../../../../../lib/report-creation.util'
import BuildingReport from './BuildingReport.vue'
import SelectReportType from './SelectReportType.vue'
import CreateWidgetReport from './CreateWidgetReport.vue'
import ReviewReportCreation from './ReviewReportCreation.vue'
import WidgetReportKindSelector from './WidgetReportKindSelector.vue'

const SettingsModule = createNamespacedHelpers('setting')
const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')

export default{
  name: 'ReportCreationBase',
  components: {
    BuildingReport,
    SelectReportType,
    CreateWidgetReport,
    ReviewReportCreation,
    WidgetReportKindSelector,
  },
  computed: {
    ...ReportsModule.mapGetters(['selectCreationStep', 'selectCreation']),
    ...SettingsModule.mapGetters(['selectUserSettings']),

    report(){
      return this.selectCreation
    },
    settings(){
      return this.selectUserSettings
    },
    currentStep(){
      return this.selectCreationStep
    },
  },
  methods: {
    ...ReportsModule.mapActions([
      'createNewReport',
      'setReportCreationField', 
      'setReportCreationStep']),
    ...LoaderModule.mapMutations(['addToLoaders', 'removeFromLoaders']),

    nextStep(step){
      this.setReportCreationStep(step)
    },
    async buildReport(){
      this.addToLoaders('build-report')
      await this.createNewReport(this.report)
      this.removeFromLoaders('build-report')
    },
    previousStep(step){
      this.setReportCreationStep(step)
    },
    updateReportType(value){
      this.setReportCreationField({ type: value })

      if(value === REPORT.TYPES.CHART){
        this.setReportCreationStep(REPORT.STEPS.BUILD_CHART_REPORT)
      }
      if(value === REPORT.TYPES.WIDGET){
        this.setReportCreationStep(REPORT.STEPS.SELECT_WIDGET_REPORT_KIND)
      }
    },
    updateCreationFields(value){
      this.setReportCreationField({ ...value })
    },
    updateWidgetReportKind(value){
      this.setReportCreationField({ kind: value })
    }
  }
}
</script>