<template>
  <div class="review-report-wrapper">
    <div class="title mb-4">
      <h6>Please review and verify information below.</h6>
      <small class="text-muted">Click create button below to build this report.</small>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered" :style="settings.fg">
      <thead>
        <tr>
          <th scope="col">Kind</th>
          <th scope="col">Type</th>
          <th scope="col">Display Name</th>
          <th scope="col">Icon</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>{{ creation.kind }}</td>
          <td>{{ creation.type }}</td>
          <td>{{ creation.displayName }}</td>
          <td><i :class="creation.icon"></i></td>
        </tr>
      </tbody>
    </table>
    </div>

    <div class="form-group">
      <button style="float: left" class="btn btn-outline-primary mt-4 mb-3" @click.prevent="previousStep">
        <i class="bi bi-arrow-left"></i> Change
      </button>

      <button style="float: right" class="btn btn-outline-success mt-4 mb-3" @click.prevent="nextStep">
        Create <i class="bi bi-tools"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import REPORT from '../../../../../lib/report-creation.util'

const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'ReviewReportCreation',
  props: ['settings'],
  computed: {
    ...ReportsModule.mapGetters(['selectCreation']),

    creation(){
      return this.selectCreation
    }
  },
  methods: {
    nextStep(){
      this.$emit('nextStep', REPORT.STEPS.INITIATE_BUILD_REPORT)
      this.$emit('buildReport', {})
    },
    previousStep(){
      this.$emit('previousStep', REPORT.STEPS.BUILD_WIDGET_REPORT)
    },
  }
}
</script>