<template>
  <div>
    <div class="temp-and-humidity-chart" v-if="summary">
      <ChartHeaders
        title="Summary"
        tyling="margin-left: 15px;"
        classStyle="chart-title text-muted card-body"
        @ChartFiltersModelShow="ChartFiltersModelShow">
      </ChartHeaders>

      <MixedChartType 
        :options="summary.options"
        :series="summary.series"/>
    </div>
    <BarChartSkeletonLoader v-if="!summary"/>
  </div>
</template>

<script>
import ChartHeaders from './chart-options/ChartHeaders.vue'
import MixedChartType from './chart-types/MixedChartType.vue'
import { createNamespacedHelpers } from 'vuex'
import BarChartSkeletonLoader from './skeleton-loader/BarchartSkeletonLoader.vue'
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'SummaryChart',
  components: {
    ChartHeaders,
    MixedChartType,
    BarChartSkeletonLoader,
  },
  data(){
    return {

    }
  },
  computed: {
    ...ReportsModule.mapGetters(['selectCharts']),

    summary(){
      return this.selectCharts.find(chart => chart.name === 'SUMMARY_CHART')
    },
  },
  methods: {
    ...ReportsModule.mapActions(['fetchSummaryChartReport', 'setSelectedChart']),

    ChartFiltersModelShow(){
      this.setSelectedChart(this.summary)
    }
  },
  async mounted() {
    await this.fetchSummaryChartReport({ limit: 15 })
  }
}
</script>