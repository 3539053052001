<template>
  <ion-list :style="settings.fg">
      <ion-item :style="settings.fg">
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <ion-item :style="settings.fg">
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
</template>

<script>
import { IonSkeletonText, IonThumbnail, IonLabel, IonList, IonItem } from '@ionic/vue'
import { createNamespacedHelpers } from "vuex"

const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: 'ConversationItemSkeleton',
  components: {
    IonSkeletonText, IonThumbnail, IonLabel, IonList, IonItem 
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    }
  }
}
</script>