<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="{...settings.bg, height: '100%'}">
      <div class="comtent-wrapper">
        <div class="container container-wrapper">
        <div class="header-body mt-5">
            <div class="row align-items-end">
              <div class="col mb-3">
                <div class="header-title mt-0 h4">
                  Dashboard
                </div>
              </div>
              <div class="col-auto">
                <a href="#!"
                  :style="{...settings.fg, marginRight: '10px'}"
                  class="btn btn-default lift mr-4"
                  data-bs-toggle="modal" 
                  data-bs-target="#createReportWizardModal"
                  @click.prevent="openReportCreationModal">
                  Create Report
                </a>
                <a
                  href="#!"
                  @click.prevent="openWidgetSettingsModal"
                  data-bs-toggle="modal" 
                  data-bs-target="#widgetSettingsModal"
                  class="btn btn-default lif" 
                  :style="settings.fg">
                  Options <i class="bi bi-filter"></i>
                </a>
              </div>
            </div> 
          </div> 
        <br>
        <div class="row mb-5">
          <!-- widgets section -->
          <div class="col-12 col-lg-6 col-xl" v-for="(widget, index) in widgetsList" :key="index">
            <MessageCountWidget v-if="widget.kind === 'MESSAGE_COUNT'" :style="settings.fg" :widget="widget"/>

            <ConversationCountWidget v-if="widget.kind === 'CONVERSATIONS_COUNT'" :style="settings.fg" :widget="widget"/>

            <ConnectionCountWidget v-if="widget.kind === 'CONNECTIONS_COUNT'" :style="settings.fg" :widget="widget" />

            <IntentCountWidget v-if="widget.kind === 'INTENT_PHRASES_COUNT'" :style="settings.fg" :widget="widget" />
          </div>
          
          <!-- <div class="col-12 col-lg-6 col-xl">
            <MessageCountWidget :style="settings.fg"/>
          </div>

          <div class="col-12 col-lg-6 col-xl">
            <MessageCountWidget :style="settings.fg"/>
          </div>

          <div class="col-12 col-lg-6 col-xl">
            <MessageCountWidget :style="settings.fg"/>
          </div> -->
          <!-- widgets section end -->

          <!-- charts section -->
          <div class="col-md-12 col-sm-12">
            <div class="card mb-4" :style="settings.fg">
              <SummaryChart/>
            </div>
          </div>

          <div class="col-md-7 col-sm-12">
            <div class="card mb-4" :style="settings.fg">
              <HumidityChart/>
            </div>
          </div>

          <div class="col-md-5 col-sm-12">
            <div class="card mb-4" :style="settings.fg">
              <TemperatureChart/>
            </div>
          </div>
          <!-- chart section end -->
        </div>
      </div>
      <!-- create report wizard modal -->
      <CreateReportWizardModal :settings="settings"/>
      <!-- create report wizard modal -->

      <!-- widget settings modal -->
      <WidgetSettingsModal :settings="settings"/>
      <!-- widget settings modal end  -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import $ from 'jquery'
import { IonContent, IonPage, IonHeader, IonToolbar } from "@ionic/vue";
import { defineComponent } from "vue"
import { createNamespacedHelpers } from "vuex"
import HumidityChart from '../charts/HumidityChart.vue'
import TemperatureChart from '../charts/TemperatureChart.vue'
import SummaryChart from '../charts/SummaryChart.vue'
import IntentCountWidget from '../widgets/IntentCountWidget.vue'
import MessageCountWidget from '../widgets/MessageCountWidget.vue'
import WidgetSettingsModal from '../modals/WidgetSettingsModal.vue'
import ConnectionCountWidget from '../widgets/ConnectionCountWidget.vue'
import ConversationCountWidget from '../widgets/ConversationCountWidget.vue'
import CreateReportWizardModal from '../modals/CreateReportWizardModal.vue'

const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')
const SettingsModule = createNamespacedHelpers('setting')
const websocketModule = createNamespacedHelpers("websocket")
const authenticationModule = createNamespacedHelpers("authentication")
const SpeechRecognitionModule = createNamespacedHelpers("speechRecognition")

export default defineComponent({
  name: "Home",
  components: {
    IonPage, 
    IonHeader, 
    IonToolbar,
    IonContent, 
    SummaryChart,
    HumidityChart,
    TemperatureChart,
    IntentCountWidget,
    MessageCountWidget,
    WidgetSettingsModal,
    ConnectionCountWidget,
    CreateReportWizardModal,
    ConversationCountWidget,
  },

  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),
    ...websocketModule.mapGetters(['getWebsocket']),
    ...authenticationModule.mapState(['httpResponse']),
    ...ReportsModule.mapGetters(['selectWidgets']),

    socket(){
      return this.getWebsocket.connected
    },
    settings(){
      return this.selectUserSettings
    },
    widgetsList(){
      return this.selectWidgets
      .filter(widget => widget.enabled && !widget.deleted )
    }
  },
  methods: {
    ...websocketModule.mapActions(['connectSocket', 'disconnectSocket']),
    ...authenticationModule.mapActions(['logout']),
    ...ReportsModule.mapActions(['loadUserReports']),
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
    ...SpeechRecognitionModule.mapActions(['fetchSpeechRecognitionSettings']),

    connect(e) {
      e.preventDefault();
      this.connectSocket();
    },
    disconnect(e) {
      e.preventDefault();
      this.disconnectSocket();
    },
    async signout(){
      await this.logout();
      const response = await this.httpResponse
      if(response.status === 200) {
        setTimeout(() => {
          this.$router.push({name: 'Login'});
        }, 2000)
      }
    },
    openReportCreationModal() {
      $('#createReportWizardModal').prependTo('body')
    },
    openWidgetSettingsModal(){
      $('#widgetSettingsModal').prependTo('body')
    },
  },
  async mounted() {
    this.addToLoaders('user-reports')
    await this.loadUserReports()
    await this.fetchSpeechRecognitionSettings()
    this.removeFromLoaders('user-reports')
  }
});
</script>

<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

.card {
  // background-color: #242526;
  border-radius: 10px;
  // border: 5px solid #18191a;
}
.card .chart-setting {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .container-wrapper {
    padding-right: 36px;
    padding-left: 36px;
  }
}
</style>