<template>
  <div>
    <div class="form-group">
      <label for="report-type">What type of report are your looking to create?</label>
      <select  class="form-select mt-3 mb-2"
        @input="updateReportType"
        aria-label="Default select example">
        <option selected>{{ form.type || 'Select report type'}} </option>
        <option v-for="type in reportTypes" :key="type.value" :value="type.value">{{ type.name }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectReportType',
  props: ['report'],
  data() {
    return {
      form: {
        type: '',
      },
      reportTypes: [
        // {
        //   name: 'Chart',
        //   value: 'chart'
        // },
        {
          name: 'Widget',
          value: 'widget'
        }
      ]
    }
  },
  methods: {
    updateReportType(event){
      this.$emit('updateReportType', event.target.value)
    }
  },
  mounted() {
    if(this.report && this.report.type){
      this.form.type = this.reportTypes.find(report => report.value === this.report.type).name
    }
  }
}
</script>