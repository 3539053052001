<template>
  <div>
    <div class="modal fade" id="widgetSettingsModal" tabindex="-1" aria-labelledby="widgetSettingsLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" >
        <div class="modal-content" :style="settings.bg">
          <div class="modal-header">
            <h5 class="modal-title" id="widgetSettingsLabel">Options</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body body-wrapper scroll mb-4">
            <div class="form-group mb-5 mt-2">
              <SearchBar :expanded="true"  
              @searchingFor="filterWidgets"/>
            </div>
            
            <div class="enabled-section" v-if="enabledWidgets.length">
              <h4 class="header-title mb-4 text-muted"> Enabled </h4> 
              <div class="row">
                <div class="col-12 col-lg-12 col-xl-12" v-for="widget in enabledWidgets" :key="widget.kind">
                  <WidgetCard 
                  :widget="widget" 
                  :style="settings.fg"
                  @updateWidgetStatus="changeWidgetStatus"/>
                </div>
              </div>
            </div>

            <div class="disabled-section" v-if="disabledWidgets.length">
              <h4 class="header-title mb-4 text-muted"> Disabled </h4> 
              <div class="row">
                <div class="col-12 col-lg-12 col-xl-12" v-for="widget in disabledWidgets" :key="widget.kind">
                  <WidgetCard 
                  :widget="widget" 
                  :style="settings.fg"
                  @updateWidgetStatus="changeWidgetStatus"/>
                </div>
              </div>
            </div>

            <div class="not-result-section" v-if="!disabledWidgets.length && !enabledWidgets.length">
              <div class="row">
                <div class="col-12 col-lg-12 col-xl-12 text-center">
                  <NoWidgetFound />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SearchBar from '../search-bar/SearchBar.vue'
import WidgetCard from '../widgets/WidgetCard.vue'
import NoWidgetFound from '../widgets/NoWidgetFound.vue'

const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'widgetSettingsModal',
  props: ['settings'],
  components: {
    SearchBar,
    WidgetCard,
    NoWidgetFound
  },
  computed: {
    ...ReportsModule.mapGetters(['selectWidgetsList']),
    
    enabledWidgets(){
      return this.selectWidgetsList.filter(w => w.enabled && !w.deleted)
    },
    disabledWidgets(){
      return this.selectWidgetsList.filter(w => !w.enabled && !w.deleted)
    },
  },
  methods: {
    ...LoaderModule.mapActions([
      'addToLoaders', 
      'removeFromLoaders'
    ]),
    ...ReportsModule.mapActions(['updateWidgetStatusById', 'searchWidget']),

    filterWidgets(searchString){
      this.searchWidget(searchString)
    },
    async changeWidgetStatus(data){
      this.addToLoaders('update-widget')
      await this.updateWidgetStatusById(data)
      this.removeFromLoaders('update-widget')
    }
  }
}
</script>
<style scoped>
  @media screen and (min-width: 768px) {
  .body-wrapper {
    padding-right: 36px;
    padding-left: 36px;
  }
  .scroll {
    max-height: 700px !important;
    overflow-y: auto;
  }
}
</style>