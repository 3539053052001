<template>
  <div class="bar-chart-wrapper">
    <apexchart
      height="220px"
      type="area"
      :options="options"
      :series="series">
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts"

export default {
  name: 'BarChartType',
  props: {
    width: {
      type: Number,
      default: 500,
      required: false
    },
    series: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  }
}
</script>