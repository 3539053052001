<template>
  <div>
    <div class="form-group">
      <label for="report-type">What kind of widget report would you like to build?</label>
      <select  class="form-select mt-3 mb-2"
        @input="updateWidgetReportKind"
        aria-label="Default select example">
        <option selected>{{ form.kind || 'Select widget kind'}} </option>
        <option v-for="kind in reportKinds" :key="kind.type" :value="kind.type">{{ kind.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <button style="float: left" class="btn btn-outline-primary mt-4 mb-3" @click.prevent="previousStep">
        <i class="bi bi-arrow-left"></i> Go back
      </button>

      <button style="float: right" class="btn btn-outline-success mt-4 mb-3" @click.prevent="nextStep">
        Continue <i class="bi bi-arrow-right"></i> 
      </button>
    </div>
  </div>
</template>
<script>
import REPORT from '../../../../../lib/report-creation.util'

export default {
  name: 'WidgetReportKindSelector',
  props: ['report'],
  data() {
    return {
      reportKinds: [
        {
          name: 'Messages Count',
          type: 'MESSAGE_COUNT',
        },
        {
          name: 'Connections Count',
          type: 'CONNECTIONS_COUNT',
        },
        {
          name: 'Conversations Count',
          type: 'CONVERSATIONS_COUNT',
        },
        {
          name: 'Intent Phrases Count',
          type: 'INTENT_PHRASES_COUNT',
        },
      ],
      form: {
        kind: ''
      }
    }
  },
  methods: {
    nextStep(){
      this.$emit('nextStep', REPORT.STEPS.BUILD_WIDGET_REPORT)
    },
    previousStep(){
      this.$emit('previousStep', REPORT.STEPS.SELECT_TYPE)
    },
    updateWidgetReportKind(event){
      this.$emit('updateWidgetReportKind', event.target.value)
    }
  },
  mounted() {
    if(this.report && this.report.type === 'widget'){
      if(this.report.kind){
        this.form.kind = this.reportKinds.find(report => report.type === this.report.kind).name
      }
    }
  }
}
</script>