<template>
  <div class="row">
      <div class="form-group">
          <label for="limit mt-2">Display Name</label>
          <input
            class="form-control mt-2"
            type="text"
            v-model="form.displayName"
            placeholder="Enter display name (e.g: Conncections Count)"
            @input="updateCreationFields"
          />
        <div class="col-md-12 mt-2 mb-4">
          <label for="date-range mt-1">Icon</label>
          <select  class="form-select mt-2 mb-2"
            @input="updateIcon"
            aria-label="Default select example">
            <option value="" selected>{{ (form.icon? form.icon.name : '') || 'Select icon type'}} </option>
            <option v-for="icon in iconsList" :key="icon.name" :value="icon.value">{{ icon.name }}</option>
          </select>

          <span v-if="form.icon"> 
            Your selected icon is: 
            <i :class="form.icon" style="font-size: 25px; margin-left: 10px"></i>
          </span>
        </div>

        <div class="col-md-12 mt-2">
          <div class="form-group">
            <button style="float: left" class="btn btn-outline-primary mt-4 mb-3" @click.prevent="previousStep">
              <i class="bi bi-arrow-left"></i> Go back
            </button>

            <button style="float: right" class="btn btn-outline-success mt-4 mb-3" @click.prevent="nextStep">
              Continue <i class="bi bi-arrow-right"></i> 
            </button>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import REPORT from '../../../../../lib/report-creation.util'

export default {
  name: "BuildWidgetReport",
  props: ['report'],
  data() {
    return {
      form: {
        icon: '',
        displayName: ''
      },
      iconsList: [
        {
          name: 'Chat dots',
          value: 'bi bi-chat-dots'
        },
        {
          name:'Chat left text',
          value: "bi bi-chat-left-text"
        },
        {
          name: 'Connections',
          value: "bi bi-hdd-network"
        },
        { 
          name: 'List Cheked',
          value: 'bi bi-list-check'
        },
        {
          name: 'List Nested',
          value: 'bi bi-list-nested'
        }
      ]
    };
  },
  methods: {
    nextStep(){
      this.$emit('nextStep', REPORT.STEPS.REVIEW_REPORT)
    },
    updateIcon(event){
      this.form.icon = event.target.value
      this.updateCreationFields()
    },
    previousStep(){
      this.$emit('previousStep', REPORT.STEPS.SELECT_WIDGET_REPORT_KIND)
    },
    updateCreationFields(){
      this.$emit('updateCreationFields', { ...this.form })
    }
  },
  mounted() {
    if(this.report){
      this.form.icon = this.iconsList.find(icon => icon.value === this.report.icon)
      this.form.displayName = this.report.displayName
    }
  }
};
</script>
