<template>
  <div class="card mb-4" style="border-radius: 10px">
    <div class="card-body">
      <div class="row align-items-center gx-0">
        <div class="col">
          <!-- Title -->
          <h6 class="text-muted mb-2">
            {{ title }}
          </h6>
          <!-- Heading -->
          <span class="h3 mb-0 text-muted">
            <AnimatedNumber 
              :startAmount="0" 
              :endAmount="value" 
              :duration="1"
              separator="1"
              decimalSeparator="."
              :autoinit="true"/>
          </span>
        </div>
        <div class="col-auto">
          <!-- Icon -->
          <i :class="`text-muted h2 ${icon}`" style="font-size: 20px"></i>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
import AnimatedNumber from "vue3-autocounter";

export default {
  name: "WidgetTypeBasic",
  props: ['title', 'value', 'icon'],
  components: {
    AnimatedNumber
  },
};
</script>
