<template>
  <BasicTypeWidget :title="widget.displayName" :value="connectionsCount" :icon="widget.icon"/>
</template>
<script>
import BasicTypeWidget from './widget-types/BasicTypeWidget.vue'
import { createNamespacedHelpers } from 'vuex'
const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'ConnectionCountWidget',
  props: ['widget'],
  components: {
    BasicTypeWidget
  },
  computed: {
    ...ReportsModule.mapGetters(['selectReports']),

    connectionsCount(){
      let count = 0

      if(this.selectReports && this.selectReports.connection){
        count = this.selectReports.connection.connectionsCount
      }
      return count
    }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchConnectionCount']),
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
  },
  async mounted() {
    this.addToLoaders('fetch-connections')
    await this.fetchConnectionCount()
    this.removeFromLoaders('fetch-connections')
  }
}
</script>