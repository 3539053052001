<template>
  <BasicTypeWidget 
    :title="widget.displayName" 
    :value="intentCount" 
    :icon="widget.icon"/>
</template>
<script>
import BasicTypeWidget from './widget-types/BasicTypeWidget.vue'
import { createNamespacedHelpers } from 'vuex'

const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'IntentCountWidget',
  props: ['widget'],
  components: {
    BasicTypeWidget
  },
  computed: {
    ...ReportsModule.mapGetters(['selectReports']),

    intentCount(){
      let count = 0
      if(this.selectReports && this.selectReports.intent){
        count = this.selectReports.intent.count
      }
      return count
    }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchIntentCount']),
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
  },
  async mounted() {
    this.addToLoaders('fetch-intent')
    await this.fetchIntentCount()
    this.removeFromLoaders('fetch-intent')
  }
}
</script>