<template>
  <div class="mixed-chart-type">
    <apexchart
      type="line"
      height="250"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "MixedChartType",
  props: {
    series: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
};
</script>
