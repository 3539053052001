<template>
  <div class="humidity-chart" >
    <div v-if="humidity">
      <ChartHeaders
        title="Humidity"
        styling="margin-left: 15px;"
        classStyle="chart-title text-muted card-body"
        @ChartFiltersModelShow="ChartFiltersModelShow">
      </ChartHeaders>
      <BarchartType
        :options="humidity.options"
        :series="humidity.series">
      </BarchartType>
    </div>
    <BarchartSkeletonLoader v-if="!humidity"/>
  </div>
</template>

<script>
import ChartHeaders from '../charts/chart-options/ChartHeaders.vue'
import BarchartType from '../charts/chart-types/BarCharType.vue'
import { createNamespacedHelpers } from 'vuex'
import BarchartSkeletonLoader from '../charts/skeleton-loader/BarchartSkeletonLoader.vue'
const ReportsModule = createNamespacedHelpers('report')
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: 'HumidityChart',
  components: {
    ChartHeaders,
    BarchartType,
    BarchartSkeletonLoader,
  },
  data(){
    return{}
  },
  computed: {
    ...ReportsModule.mapGetters(['selectCharts', 'selectRenderHumidity']),
    ...SettingsModule.mapGetters(['selectApplicationSettings']),

    humidity(){
      return this.selectCharts.find(chart => chart.name === 'HUMIDITY_CHART')
    },
    application(){
      return this.selectApplicationSettings
    }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchHumidityReport', 'setSelectedChart']),

    ChartFiltersModelShow(){
      this.setSelectedChart(this.humidity)
    }
  },
  async mounted() {
    await this.fetchHumidityReport({ limit: 7 })
  }
}
</script>

<style scoped lang="scss">
</style>