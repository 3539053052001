<template>
  <div class="card mb-4" style="border-radius: 10px">
    <div class="card-body">
      <div class="row align-items-start gx-0">
        <div class="col">
          <!-- Title -->
          <h6 class="header-pretitle text-muted">
            Name
          </h6>
          <h6 class="text-capitalize text-truncate">
            {{ widget.displayName }}
          </h6>
        </div>
        <div class="col">
          <h6 class="header-pretitle text-muted">
            Created
          </h6>
          <h6 class="text-capitalize">
            {{ dateFormatter(widget.createdAt) }}
          </h6>
        </div>

        <div class="col icon-column">
          <h6 class="header-pretitle text-muted">
            Icon
          </h6>
          <i :class="`h2 ${widget.icon}`" style="font-size: 24px"></i> 
        </div>

        <div class="col-auto">
          <h6 class="header-pretitle text-muted">
            Status
          </h6>
          <ion-toggle 
            :checked="isChecked"
            @ionChange.prevent="updateWidgetStatus" 
            class="mt-2" mode="ios" color="success"></ion-toggle>
        </div>
        
      </div>
      <!-- / .row -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { IonToggle } from '@ionic/vue'

export default {
  name: "WidgetCard",
  props: ['widget'],
  components: {
    IonToggle
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    dateFormatter(date){
      return moment(date).format('LL')
    },
    updateWidgetStatus(){
      this.$emit('updateWidgetStatus', {
        enabled: !this.widget.enabled,
        widgetId: this.widget._id
      })
    }
  },
  mounted() {
    if(this.widget){
      this.isChecked = this.widget.enabled ? true : false
    }
  }
};
</script>
<style scoped>
  @media screen and (max-width: 768px){
    .icon-column {
      display: none
    }
  }
</style>
