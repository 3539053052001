<template>
  <div class="search-bar">
    <div class="form-group">
      <div class="search">
        <input
          :style="settings.bg"
          :class="expanded ? 'form-control' : ''"
          type="text"
          name="search"
          v-model="searchString"
          @input="searching"
          placeholder="Search"
        />
        <ion-icon class="fa-search" name="search-outline"></ion-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { IonIcon } from "@ionic/vue";
const SettingsModule = createNamespacedHelpers("setting");

export default {
  name: "SearchBar",
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      searchTerm: null,
    };
  },
  components: {
    IonIcon,
  },
  computed: {
    ...SettingsModule.mapGetters(["selectUserSettings"]),

    settings() {
      return this.selectUserSettings;
    },
    searchString: {
      get: function() {
        return this.searchTerm;
      },
      set: function(value) {
        this.searchTerm = value;
      },
    },
  },
  methods: {
    searching() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.emitSearchTerm, 1000);
    },
    emitSearchTerm() {
      this.$emit("searchingFor", this.searchTerm);
    },
  },
};
</script>
<style scoped>
input[type="text"] {
  border-radius: 25px;
}
.search {
  position: relative;
}

.search input {
  border: 1px solid #aaa;
}

.search input {
  text-indent: 32px;
}
.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
