<template>
  <BasicTypeWidget :title="widget.displayName" :value="conversationCount" :icon="widget.icon"/>
</template>
<script>
import BasicTypeWidget from './widget-types/BasicTypeWidget.vue'
import { createNamespacedHelpers } from 'vuex'
const LoaderModule = createNamespacedHelpers('loaders')
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'ConversationCountWidget',
  props: ['widget'],
  components: {
    BasicTypeWidget
  },
  computed: {
    ...ReportsModule.mapGetters(['selectReports']),

    conversationCount(){
      let count = 0

      if(this.selectReports && this.selectReports.conversation){
        count = this.selectReports.conversation.conversationsCount
      }
      return count
    }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchConversationCount']),
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
  },
  async mounted() {
    this.addToLoaders('fetch-conversation')
    await this.fetchConversationCount()
    this.removeFromLoaders('fetch-conversation')
  }
}
</script>