<template>
  <div class="temperature-chart">
    <div v-if="temperature">
      <ChartHeaders
        title="Temperature"
        styling="margin-left: auto;"
        classStyle="chart-title text-muted card-body"
        @ChartFiltersModelShow="ChartFiltersModelShow">
      </ChartHeaders>
      <BarchartType
        :options="temperature.options"
        :series="temperature.series">
      </BarchartType>
    </div>
    <BarChartSkeletonLoader v-if="!temperature"/>
  </div>
</template>

<script>
import ChartHeaders from './chart-options/ChartHeaders.vue'
import BarchartType from './chart-types/BarCharType.vue'
import { createNamespacedHelpers } from 'vuex'
import BarChartSkeletonLoader from './skeleton-loader/BarchartSkeletonLoader.vue'

const ReportsModule = createNamespacedHelpers('report')
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: 'TemperatureChart',
  components: {
    ChartHeaders,
    BarchartType,
    BarChartSkeletonLoader,
  },
  data(){
    return{}
  },
  computed: {
    ...ReportsModule.mapGetters(['selectCharts', 'selectRenderTemperature']),
    ...SettingsModule.mapGetters(['selectApplicationSettings']),

    temperature(){
      return this.selectCharts.find(chart => chart.name === 'TEMPERATURE_CHART')
    },
    application(){
      return this.selectApplicationSettings
    }
  },
  methods: {
    ...ReportsModule.mapActions(['fetchTemperatureReport', 'setSelectedChart']),

    ChartFiltersModelShow(){
      this.setSelectedChart(this.temperature)
    }
  },
  async mounted() {
    await this.fetchTemperatureReport({ limit: 7 })
  }
}
</script>

<style scoped lang="scss">

</style>