<template>
  <div>
    <span>
      <i class="bi bi-search" style="font-size: 50px"></i>
      <h3 class="header-title">No matching widgets found!</h3>
    </span>
    <span class="header-pretitle text-muted">
      There weren't any saved widgets, recent queries, or source matching your search.
    </span>
  </div>
</template>
<script>
export default {
  name: "NotWidgetFound",
}
</script>