<template>
  <div class="building-report-wrapper">
    <div class="text-center" v-if="isCreating">
      <h4>Report building in progress</h4>
      <div class="d-flex justify-content-center">
        <div class="spinner-grow spinner-grow-md text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-md text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-md text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="text-center text-success" v-else >
      <i class="bi bi-check-circle" style="font-size: 100px; color: 'green'"></i>
      <p class=" text-success">Your report was created successfully</p>
    </div>

    <div class="col-md-12 mt-2">
      <div class="form-group text-center">
        <button class="btn btn-outline-success mt-4 mb-3 form-control" @click.prevent="finished">
          Done
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const ReportsModule = createNamespacedHelpers('report')

export default {
  name: 'BuildingReport',
  computed: {
    ...ReportsModule.mapGetters(['selectIsReportCreating']),

    isCreating(){
      return this.selectIsReportCreating
    }
  },
  methods: {
    finished(){
      this.$router.go({})
    }
  }
}
</script>
<style scoped>
  .spinner-grow {
    margin: 5px;
  }
</style>