<template>
  <div>
    <div class="modal fade" id="createReportWizardModal" tabindex="-1" aria-labelledby="reportCreationLable" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content" :style="settings.bg">
          <div class="modal-header">
            <h5 class="modal-title" id="reportCreationLable">Report Creation Wizard </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ReportCreationBase/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportCreationBase from '../reports/creation/ReportCreationBase.vue'
export default {
  name: 'CreateReportWizardModal',
  props: ['settings'],
  components: {
    ReportCreationBase
  }
}
</script>